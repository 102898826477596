import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm a web developer in Milwaukee, Wisconsin who loves developing and even designing frontend. I also have experience writing backend C# and .NET Core, particularly in developing APIs. I have an insatiable appetite for learning new technologies, and I thrive when working on challenging projects with creative people.`}</p>
    <h2>{`skills`}</h2>
    <p>{`React, JavaScript, TypeScript, CSS modules, CSS-in-JS, SCSS, web accessibility, Cypress, Jest, C#, .NET Core, SQL, Node, UX & Responsive Design, agile development`}</p>
    <h2>{`details`}</h2>
    <p>{`Need to know more? `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1PZry7DwvFVVlxU4UNlyhHxG4iqe-M120JYLPhigRHmA/edit?usp=sharing"
      }}>{`Download my resume`}</a>{`.`}</p>
    <h2>{`get in touch`}</h2>
    <p>{`I'm always available for a chat - whether you want to talk about work, accessibility, or just get in touch. The best way to reach me is at `}<a parentName="p" {...{
        "href": "mailto:hello@kattow.dev"
      }}>{`hello@kattow.dev`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      